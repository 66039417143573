<template>
  <div>
    <div class="item_title">
      <div class="item_titleline"></div>
      订单管理
      <router-link tag="a" class="item_titleText" to="/materialDemand/materialDemandOrder"><< 返回上一级</router-link>
    </div>
    <div class="content">
      <div class="order_detail">
        <div class="order_detail_title">订单信息</div>
      </div>
      <div class="order_detail_item">
        <a-row>
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="order_detail_text">订单编号</div>
              </a-col>
              <a-col :span="10">
                <div class="order_detail_text_1">{{ obj.orderNo }}</div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="order_detail_text">商品总价</div>
              </a-col>
              <a-col :span="17">
                <div class="order_detail_text_1">￥{{ $route.query.totalAmount }}</div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="order_detail_text">订单总价</div>
              </a-col>
              <a-col :span="17">
                <div class="order_detail_text_1">￥{{ obj.amount }}</div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <div class="order_detail_item">
        <a-row>
          <!-- <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="order_detail_text">最晚收货时间</div>
              </a-col>
              <a-col :span="17">
                <div class="order_detail_text_1">{{ obj.deliveryTime }}</div>
              </a-col>
            </a-row>
          </a-col> -->
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="order_detail_text">订单生成时间</div>
              </a-col>
              <a-col :span="17">
                <div class="order_detail_text_1">{{ obj.createdTime }}</div>
              </a-col>
            </a-row>
          </a-col>
          <!-- <a-col :span="8">
            <a-row>
              <a-col :span="6">11
                <div class="order_detail_text">运费合计</div>
              </a-col>
              <a-col :span="17">
                <div class="order_detail_text_1">￥{{ obj.freight }}</div>
              </a-col>
            </a-row>

          </a-col> -->
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="order_detail_text">收货地址</div>
              </a-col>
              <a-col :span="17">
                <div class="order_detail_text_1">
                  {{ obj.shippingAddress }}
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <div class="order_detail_item">
        <a-row>
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="order_detail_text">备注信息</div>
              </a-col>
              <a-col :span="17">
                <div class="order_detail_text_1">{{ obj.remark }}</div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <div class="order_detail">
        <div class="order_detail_title">供应商信息</div>
      </div>
      <div class="order_detail_item">
        <a-row>
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="order_detail_text">供应商logo</div>
              </a-col>
              <a-col :span="17">
                <div class="order_img">
                  <img :src="obj.logo" alt="" />
                </div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="order_detail_text">供应商名称</div>
              </a-col>
              <a-col :span="17">
                <div class="order_detail_text_1">{{ obj.supplierName }}</div>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-row>
              <a-col :span="6">
                <div class="order_detail_text">供应商所在地区</div>
              </a-col>
              <a-col :span="17">
                <div class="order_detail_text_1">{{ obj.province }}{{ obj.city }}{{ obj.county }}</div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <div class="order_detail_item">
        <a-row>
          <a-col :span="8"> </a-col>
          <a-col :span="8">
            <a-row style="margin-top: -60px">
              <a-col :span="6">
                <div class="order_detail_text">供应商联系电话</div>
              </a-col>
              <a-col :span="17">
                <div class="order_detail_text_1">{{ obj.phone }}</div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>

      <div class="order_detail">
        <div class="order_detail_title">订单商品信息</div>
      </div>
      <p-table
        :columns="columns"
        :scroll="{ y: 0 }"
        extraHeight="670"
        :pagination="false"
        :sourceData="handleGetDataApi"
        row-key="orderNo"
      >
      </p-table>
    </div>
  </div>
</template>

<script>
import { employmentDetail } from '@/api/materialShop'
import { dateFormat } from '@/common/common'
import { Timeline } from 'ant-design-vue'

const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    width: 60,
    customRender: (t, r, i) => i + 1
  },
  {
    title: '商品品类',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: '商品名称',
    dataIndex: 'productName',
    key: 'productName'
  },
  {
    title: '规格',
    dataIndex: 'specifications',
    key: 'specifications'
  },
  {
    title: '型号',
    dataIndex: 'model',
    key: 'model'
  },
  {
    title: '计量单位',
    dataIndex: 'unit',
    key: 'unit'
  },
  {
    title: '数量',
    dataIndex: 'num',
    key: 'num'
  }
]

export default {
  name: 'Detail',
  components: {
    [Timeline.Item.name]: Timeline.Item
  },
  data () {
    return {
      obj: {},
      columns
    }
  },
  mounted () {
    this.init(this.$route.query.id)
  },
  methods: {
    init (id) {
      const params = {
        id: this.$route.query.id
      }
      employmentDetail(params)
        .then((res) => {
          res.data[0].deliveryTime = dateFormat(res.data[0].deliveryTime)
          res.data[0].createdTime = dateFormat(res.data[0].CREATE_TIME)
          this.obj = res.data[0]
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleGetDataApi () {
      const params = {
        id: this.$route.query.id
      }
      return employmentDetail(params)
    }
  }
}
</script>

<style lang="less">
@import url('../../../common/style');
@import url('./index');

.ant-timeline-item-content {
  text-align: left;
}
</style>
